.ArtistList-flex-container {
  display: flex;
  flex-flow: wrap;
  justify-content: center;

  animation-name: fade-up;
  animation-duration: .5s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
