.LoadingCard-container {
  width: 100%;
  margin: 0 0 10px 0;
  background: #fff;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px,
              rgba(0, 0, 0, 0.12) 0px 1px 4px;
  border-radius: 3px;
  transition: box-shadow .3s cubic-bezier(0.4, 0.0, 0.2, 1);
}

/****************** Media Query *****************/

@media only screen and (min-width: 380px) {
  .LoadingCard-container {
    width: 380px;
  }
}

@media only screen and (min-width: 792px) {
  .LoadingCard-container {
    margin: 8px;
  }
}