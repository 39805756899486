.RecordPage-container {
  margin: 0 0 10px;
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  background-color: #fff;

  animation-name: fade-up;
  animation-duration: .5s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

.RecordPage-content {
  display: flex;
  flex-direction: column; 
}

.RecordPage-img {
  width: 300px;
  align-self: center;
}

.RecordPage-review {
  padding: 20px;
  flex-grow: 1;
}

/****************** Media Query *****************/ 

@media only screen and (min-width: 768px) {
  .RecordPage-content {
    flex-direction: row;
  }
}