.AboutPage-container {
  display: flex;
  flex-flow: column;
  margin: 0 0 10px;
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  background-color: #fff;

  animation-name: fade-up;
  animation-duration: .5s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
