.AuthPage-container {
  max-width: 450px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  background-color: #fff;

  animation-name: fade-up;
  animation-duration: .5s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

.AuthPage-button {
  display: flex;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  text-transform: uppercase;
  border: 10px;
  font-family: Roboto, sans-serif;
  cursor: pointer;
  margin: 0px auto;
  padding: 0px 20px;
  outline: none;
  height: 36px;
  line-height: 36px;
  min-width: 88px;
  border-radius: 2px;
  background-color: rgb(14, 97, 253);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
}


.AuthPage-button:hover {
  background-color: rgb(86, 143, 251);
}

.AuthPage-user-container {
  display: flex;
  justify-content: center;
  background-color: rgb(232, 232, 232);
  padding: 20px;
  margin: 10px 30px;
  border-radius: 3px;
}

.AuthPage-user-photo {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  align-self: center;
}

.AuthPage-user-info {
  margin-left: 20px;
}
