.FeaturedCard-container {
  width: 100%;
  margin: 0 0 10px 0;
  padding: 16px 0 8px;
  background: #fff;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px,
              rgba(0, 0, 0, 0.12) 0px 1px 4px;
  border-radius: 3px;
  transition: box-shadow .3s cubic-bezier(0.4, 0.0, 0.2, 1);
}

.FeaturedCard-container:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
              0 6px 6px rgba(0, 0, 0, 0.23);
}

.avatar-class {
  margin-right: 0 !important;
}

.FeaturedCard-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 3px;
}

/****************** Media Query *****************/

@media only screen and (min-width: 792px) {
  .FeaturedCard-container {
    margin: 8px;
  }
  .avatar-class {
    margin-right: 16px !important;
  }
  .FeaturedCard-image {
    width: 160px;
    height: 160px;
  }
}

@media only screen and (min-width: 808px) {
  .FeaturedCard-container {
    width: 83.5%;
    min-width: 776.547px;
    margin-bottom: 8px;
  }
}

@media only screen and (min-width: 1475px) {
  .FeaturedCard-container {
    width: 1170px;
    max-width: 1170px;
  }
}