.App {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
}

.App-panel {
  flex: 1;
  margin: 10px auto 0;
  width: 96%;
  border-radius: 3px;
}

li {
  text-align: left;
}

.App-footer {
  opacity: 1;
  margin: 24px 0 32px;
  position: relative;
  z-index: -2;
  transition: opacity 1s ease;
}

.App-footer.hide {
  opacity: 0
}

@media only screen and (min-width: 960px) {
  .App-panel {
    width: 930px;
  }
}

@media only screen and (min-width: 1475px) {
  .App-panel {
    width: 1227px;
  }
}
