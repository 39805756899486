svg.spinner {
  display: block;
  margin: 50px auto;
  width: 40px;
  height: 40px;
}

svg.spinner circle {
  fill: transparent;
  stroke: rgb(14, 97, 253);
  stroke-width: 4;
  stroke-linecap: round;
  stroke-dasharray: 125.6;
  transform-origin: 20px 20px 0;
  animation: spinner 2s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
    stroke-dashoffset: 26.4;
  } 50% {
    transform: rotate(720deg);
    stroke-dashoffset: 125.6;
  } 100% {
    transform: rotate(1080deg);
    stroke-dashoffset: 26.4;
  }
}